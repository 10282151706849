import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, Box, Paper, Typography } from "@mui/material";
import Http from "../../../lib/Http";
import AuthContext from "../../../context/AuthContext";
import { useContext } from "react";

export default function Files({ dataInvestor }) {
	const { register, handleSubmit } = useForm();
	const [fileSelected, setFileSelected] = useState(null);
	const { currentUser } = useContext(AuthContext);

	console.log(dataInvestor);
	const onFilechange = (e) => {
		//e.target.files[0].name = "mi-contrato.pdf";
		const file = e.target.files[0];
		var new_file = new File([file], "otro nombre.pdf");
		console.log(new_file);

		setFileSelected(new_file);
	};

	const onSubmit = async (data) => {
		console.log(data);
		const f = new FormData();
		f.append("data", fileSelected);

		let response = await Http.instance.uploadFile(f);
		console.log(response);
	};

	const updateFile = async (data) => {
		console.log(data);
		const f = new FormData();
		f.append("data", fileSelected);

		let response = await Http.instance.updateFile(f, 13);
		console.log(response);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper>
						<Typography variant="h3" textAlign={"center"} color={"primary"}>
							Mis Documentos
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<Typography variant="h4" textAlign={"center"} color={"primary"}>
							Documento de identidad - IDE
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={12} md={6}>
					<p>Frontal</p>
				</Grid>

				<Grid item xs={12} md={6}>
					<p>Posterior</p>
				</Grid>

				<Grid item xs={5}>
					<input type="file" name="contratoss" onChange={onFilechange} />
				</Grid>

				<Grid item xs={3}>
					<Button variant="contained" size="large" onClick={onSubmit}>
						Enviar
					</Button>
				</Grid>
				<Grid item xs={3}>
					<Button variant="contained" size="large" onClick={updateFile}>
						Actualizar
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
