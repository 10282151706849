import React from "react";
import moment from "moment";
import { Grid, Paper, TextField, Button } from "@mui/material";

export default function WithDrawalsDataGroup({ currentRow, setOpenModal }) {
	return (
		<>
			<Paper
				sx={{
					width: "90%",
					alignSelf: "center",
					height: "auto",
					maxHeight: "80vh",
					padding: 2,
					marginTop: 1,
					overflowX: "auto",
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label="Id Inversor"
							variant="outlined"
							value={currentRow?.inversor}
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label="Email Inversor"
							variant="outlined"
							value={currentRow?.email}
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label="Fecha de solicitud"
							variant="outlined"
							value={moment(currentRow?.created_on).format("YYYY/MM/DD HH:MM")}
							fullWidth
							disabled
						/>
					</Grid>
					{/*fin Primera fila */}

					{/* Datos retiro */}
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Nombre Inversor"
							variant="outlined"
							value={currentRow?.nombre}
							fullWidth
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Moneda"
							variant="outlined"
							value={currentRow?.moneda === "1" ? "MXN" : "USD"}
							fullWidth
							disabled
						/>
					</Grid>

					{/* fin monto y moneda */}

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Metodo de retiro"
							variant="outlined"
							value={
								currentRow?.metodo_de_retiro === "1"
									? "Transferencia Bancaria"
									: "Cripto USDT"
							}
							fullWidth
							disabled
						/>
					</Grid>

					{/* Condicional si fue tranferencia o cripto */}
					{currentRow?.metodo_de_retiro === "1" ? (
						<>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label="Banco"
									variant="outlined"
									value={currentRow?.banco}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label="Clabe"
									variant="outlined"
									value={currentRow?.clabe}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label="Cuenta Bancaria"
									variant="outlined"
									value={currentRow?.cuenta_bancaria}
									fullWidth
								/>
							</Grid>
						</>
					) : (
						<>
							<Grid item xs={12} sm={6} md={9}>
								<TextField
									label="Wallet de destino"
									variant="outlined"
									value={currentRow?.wallet}
									fullWidth
								/>
							</Grid>
						</>
					)}
					{/* fibnd fila 2 */}

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Pais"
							variant="outlined"
							value={currentRow?.pais}
							fullWidth
							disabled
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Ciudad"
							variant="outlined"
							value={currentRow?.ciudad}
							fullWidth
							disabled
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Provincia - Estado"
							variant="outlined"
							value={currentRow?.provincia}
							fullWidth
							disabled
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="Estado"
							variant="outlined"
							value={
								currentRow?.status === "draft" ? "Pendiente" : "Confirmado"
							}
							fullWidth
							color="primary"
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							color="secondary"
							fullWidth
							variant="contained"
							onClick={() => setOpenModal(false)}
						>
							Cerrar
						</Button>
					</Grid>
					{/* Final fila 3 */}
				</Grid>
			</Paper>
		</>
	);
}
