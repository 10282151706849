import Axios from "axios";
import Http, { isDev, PROJECT, URL_BASE, _currentUser } from "./Http";

export default class HttpAdmin extends Http {
	static instance = new HttpAdmin();

	constructor() {
		super();
	}

	getAllFundingDraftSuperAdmin = async () => {
		isDev && this.axiosInterceptor();

		const uri = `${URL_BASE}/public/${PROJECT}/items/fondeos?filter[status]=draft&fields=*.*`;
		try {
			let response = await Axios.get(uri);

			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los fondeos  exitosa",
			};
		} catch (error) {
			console.log(error);
			console.log(error.message);
			const { status } = error.response;
			let data = {
				error,
				functionName: "getAllFundingSuperAdmin",
				other: [{ status: status }],
				message: "leer todos los fondeos de todos los usuarios",
			};
			this.manageError(data);
		}
	};

	getGroupAllWithdrawalDraftSuperAdmin = async () => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros/todos`;
		try {
			let response = await Axios.get(uri);
			console.log(response.data.data.data);
			return {
				status: 200,
				data: response.data.data,
				message: "Lectura de todos los retiros pendientes exitosa",
			};
		} catch (error) {
			console.log(error);
			console.log(error.message);
			const { status } = error.response;
			let data = {
				error,
				functionName: "getMoneyInvestmentFund",
				other: [{ status: status }],
				message:
					"Hubo un error intentando consultar los montos de todas las inversiones disponibles",
			};
			this.manageError(data);
		}
	};

	deliverReturnsInGroup = async (data) => {
		isDev && this.axiosInterceptor();
		const uri = `${URL_BASE}/public/${PROJECT}/custom/retiros/entregar`;

		try {
			let response = await Axios({
				method: "post",
				url: uri,
				data: data.data,
			});

			return {
				status: 200,
				data: response.data.data,
				message: "Entrega de retiros  exitosa y cambio de estados en el fondo",
			};
		} catch (error) {
			console.log(error);
		}
	};
}
