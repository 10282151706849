import React from "react";
import { Grid, Box, Paper, Button, Typography } from "@mui/material";

export default function InMaintenance() {
	return (
		<Grid container>
			<Grid
				sx={{
					display: "flex",
					flexGrow: 1,
					alignContent: "center",
					textAlign: "center",
				}}
			>
				<Typography
					variant="h3"
					sx={{
						justifyContent: "center",
						color: "gray",
						flexGrow: 1,
						marginTop: 5,
					}}
				>
					Sitio en mantenimiento
				</Typography>
				;
			</Grid>
		</Grid>
	);
}
