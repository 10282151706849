import { createContext, useState, useEffect } from "react";
import Http, { KEY_CURRENT_USER, cookies } from "../lib/Http";
import HttpAdmin from "../lib/HttpAdmin";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
	const currentUser = cookies.get(KEY_CURRENT_USER);
	const [allFundingSuperAdmin, setAllFundingSuperAdmin] = useState([]);
	const [allfundingDraf, setAllfundingDraf] = useState([]);
	const [allWithdrawalDraft, setAllWithdrawalDraft] = useState([]);

	const fundingRequests = async () => {
		//	let response = await Http.instance.getAllFundingDraftSuperAdmin();
		let response = await HttpAdmin.instance.getAllFundingDraftSuperAdmin();
		setAllfundingDraf(response.data);
	};

	const withdrawalRequest = async () => {
		let response = await Http.instance.getAllWithdrawalDraftSuperAdmin();
		if (response.status === 200) {
			setAllWithdrawalDraft(response.data);
		} else {
			alert(
				"Ocurrio un error en la lectura de retiros pendientes, cierre sesión y vuelva a interntar ingresar"
			);
			console.log(response);
		}
	};

	const sanitizeCurrentUser = currentUser || false;

	useEffect(() => {
		if (sanitizeCurrentUser.role === "4") {
			//validateCurrentInvestor();
		} else if (sanitizeCurrentUser.role === "5") {
			fundingRequests();
			withdrawalRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const data = {
		allfundingDraf,
		allWithdrawalDraft,
		fundingRequests,
		withdrawalRequest,
	};
	return (
		<NotificationContext.Provider value={data}>
			{children}
		</NotificationContext.Provider>
	);
};

export { NotificationProvider };
export default NotificationContext;
