import {
	Grid,
	Paper,
	Typography,
	Card,
	Box,
	CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { separator } from "../../../lib/formatNumber";
import Http from "../../../lib/Http";

export default function GeneralInformation() {
	const [loading, setLoading] = useState(true);
	const [allMoneyData, setAllMoneyData] = useState([]);
	const [utilities15, setUtilities15] = useState([]);
	const [utilities27, setUtilities27] = useState([]);
	const [allReturnsDelivered, setallReturnsDelivered] = useState([]);

	let getAllmoneyActive = async () => {
		let response = await Http.instance.getMoneyInvestmentFund();
		let data = response.data;

		let mount = 0;
		data.forEach((investment) => {
			/* filtar usuarios de prueba  */
			switch (investment.inversor) {
				case 60:
					console.log(investment);
					break;
				case 61:
					break;
				case 62:
					break;
				case 64:
					break;
				case 73:
					break;
				case 310:
					break;
				case 312:
					break;

				default:
					mount += investment.monto;
					break;
			}
		});
		setAllMoneyData(mount);
	};

	let getAllReturns = async () => {
		let response = await Http.instance.getAllReturnsDelivered();
		let data = response.data;

		let mount = 0;
		data.forEach((investment) => {
			mount += investment.monto;
		});
		setallReturnsDelivered(mount);
	};

	let getAllmoneyActive15 = async () => {
		let response = await Http.instance.getMoneyInvestmentFund15();
		let data = response.data;

		let mount = 0;
		data.forEach((investment) => {
			mount += investment.monto;
		});
		setUtilities15(mount);
	};

	let getAllmoneyActive27 = async () => {
		let response = await Http.instance.getMoneyInvestmentFund27();
		let data = response.data;

		let mount = 0;
		data.forEach((investment) => {
			mount += investment.monto;
		});
		setUtilities27(mount);
	};

	useEffect(() => {
		setLoading(true);
		getAllmoneyActive();
		getAllReturns();
		getAllmoneyActive15();
		getAllmoneyActive27();
		setLoading(false);
	}, []);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card sx={{ marginBottom: 2 }}>
					<Typography
						textAlign={"center"}
						color="primary"
						variant="h4"
						m={1}
						p={2}
					>
						Informacion del fondo
					</Typography>
				</Card>
			</Grid>
			<Grid container spacing={{ xs: 2, md: 5 }}>
				<Grid item xs={12} md={4}>
					<Card sx={{ flexGrow: 1 }} elevation={3}>
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={2}
						>
							Dinero en el Fondo
						</Typography>
						<Box
							sx={{
								border: "dashed 1px gray",
								margin: 3,
								borderRadius: 2,
								padding: 3,
								minHeight: 100,
							}}
						>
							<Typography textAlign={"center"} color="primary" variant="h3">
								{loading ? (
									<CircularProgress />
								) : (
									`${separator(allMoneyData)} MX`
								)}
							</Typography>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card elevation={3}>
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={2}
						>
							Rendimientos para este periodo:
						</Typography>
						<Box
							sx={{
								border: "dashed 1px gray",
								margin: 3,
								borderRadius: 2,
								padding: 3,
							}}
						>
							<Typography textAlign={"center"} color="primary" variant="h3">
								{loading ? (
									<CircularProgress />
								) : (
									`${separator((allMoneyData * 0.07).toFixed(0))} MX`
								)}
							</Typography>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card elevation={3}>
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={2}
						>
							Dinero entregado en retiros a la fecha
						</Typography>
						<Box
							sx={{
								border: "dashed 1px gray",
								margin: 3,
								borderRadius: 2,
								padding: 3,
							}}
						>
							<Typography textAlign={"center"} color="primary" variant="h3">
								{loading ? (
									<CircularProgress />
								) : (
									`${separator(allReturnsDelivered)} MX`
								)}
							</Typography>
						</Box>
					</Card>
				</Grid>

				{/*  -----------  Rendimientos a dar entregar por periodo ---------- */}

				<Grid item xs={12}>
					<Card sx={{ marginBottom: 1 }} color="primary">
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={1}
						>
							Rendimientos a dar entregar por periodo
						</Typography>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card sx={{ flexGrow: 1 }} elevation={3}>
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={2}
						>
							Rendimientos a entregar el 15
						</Typography>
						<Box
							sx={{
								border: "dashed 2px gray",
								margin: 3,
								borderRadius: 2,
								padding: 3,
								minHeight: 100,
							}}
						>
							<Typography textAlign={"center"} color="primary" variant="h3">
								{loading ? (
									<CircularProgress />
								) : (
									`${separator((utilities15 * 0.07).toFixed(0))} MX`
								)}
							</Typography>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card elevation={3}>
						<Typography
							textAlign={"center"}
							color="primary"
							variant="h5"
							m={1}
							p={2}
						>
							Rendimientos a entregar el 27
						</Typography>
						<Box
							sx={{
								border: "dashed 2px gray",
								margin: 3,
								borderRadius: 2,
								padding: 3,
							}}
						>
							<Typography textAlign={"center"} color="primary" variant="h3">
								{loading ? (
									<CircularProgress />
								) : (
									`${separator((utilities27 * 0.07).toFixed(0))} MX`
								)}
							</Typography>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</Grid>
	);
}
