import React, { useContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Http, { localConnect } from "../../../lib/Http";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

/* iconos */
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import AddchartIcon from "@mui/icons-material/Addchart";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { Badge } from "@mui/material";
import NotificationContext from "../../../context/NotificationContext";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const ListNavigationAdmin = ({ navigate, signOut, open }) => {
  const {
    allWithdrawalDraft,
    allfundingDraf,
    fundingRequests,
    withdrawalRequest,
  } = useContext(NotificationContext);

  useEffect(() => {
    fundingRequests();
    withdrawalRequest();
  }, []);

  const handelNavigate = (ruta) => {
    navigate(ruta);
    if (open) {
      open(false);
    }
  };

  return (
    <List>
      <>
        <ListItem button onClick={() => handelNavigate("/")}>
          <ListItemIcon>
            <Badge badgeContent={allfundingDraf.length} color="primary">
              <InboxIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={"Nuevas Inversiones"} />
        </ListItem>
        <ListItem button onClick={() => handelNavigate("/admin-retiros")}>
          <ListItemIcon>
            <Badge badgeContent={allWithdrawalDraft.length} color="primary">
              <PriceCheckIcon />
            </Badge>
          </ListItemIcon>

          <ListItemText primary={" Administrar Retiros"} />
        </ListItem>
        <ListItem button onClick={() => handelNavigate("/grupo-retiros")}>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={"Retiros en grupos"} />
        </ListItem>
        <ListItem button onClick={() => handelNavigate("/admin-inversiones")}>
          <ListItemIcon>
            <StackedBarChartIcon />
          </ListItemIcon>
          <ListItemText primary={"Administrar Inversiones"} />
        </ListItem>
        <ListItem button onClick={() => handelNavigate("/consultar-inversor")}>
          <ListItemIcon>
            <PersonSearchIcon />
          </ListItemIcon>
          <ListItemText primary={"Consultar Inversor"} />
        </ListItem>
        <ListItem button onClick={() => handelNavigate("/fondo")}>
          <ListItemIcon>
            <LineAxisIcon />
          </ListItemIcon>
          <ListItemText primary={"Informacíon del Fondo"} />
        </ListItem>
      </>
      {/* <ListItem button onClick={() => handelNavigate("/perfil")}>
				<ListItemIcon>
					<AccountCircleIcon />
				</ListItemIcon>
				<ListItemText primary={"Mi Perfil"} />
			</ListItem> */}

      <ListItem
        button
        onClick={() => {
          window.open(
            "https://lobosmillonarios.com/fondo/public/admin/#/login",
            "_blank"
          );
          return null;
        }}
      >
        <ListItemIcon>
          <DashboardCustomizeIcon />
        </ListItemIcon>
        <ListItemText primary={"Panel Avanzado"} />
      </ListItem>
    </List>
  );
};

const ListNavigationInversor = ({ navigate, signOut, dataInvestor, open }) => {
  const [sanitizateDataInvestor, setsanitizateDataInvestor] = useState(
    dataInvestor || {}
  );
  const handelNavigate = (ruta) => {
    navigate(ruta);
    if (open) {
      open(false);
    }
  };

  useEffect(() => {
    setsanitizateDataInvestor(dataInvestor);
  }, [dataInvestor]);

  return (
    <List>
      {sanitizateDataInvestor.status === "published" && (
        <>
          <ListItem button onClick={() => handelNavigate("/")}>
            <ListItemIcon>
              <StackedBarChartIcon />
            </ListItemIcon>
            <ListItemText primary={"Mis Inversiones"} />
          </ListItem>
          <ListItem button onClick={() => handelNavigate("/fondear")}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={" Invertir"} />
          </ListItem>
          <ListItem button onClick={() => handelNavigate("/retirar-todo")}>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={" Retirar Todo"} />
          </ListItem>
          <ListItem button onClick={() => handelNavigate("/retiros")}>
            <ListItemIcon>
              <PriceCheckIcon />
            </ListItemIcon>
            <ListItemText primary={" Información Retiros"} />
          </ListItem>
          {/* <ListItem button onClick={() => handelNavigate("/archivos")}>
						<ListItemIcon>
							<FileCopyIcon />
						</ListItemIcon>
						<ListItemText primary={" Mis Archivos"} />
					</ListItem> */}
        </>
      )}
      <ListItem button onClick={() => handelNavigate("/perfil")}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={"Perfil"} />
      </ListItem>
    </List>
  );
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard(props) {
  const { name, user, allFundingSuperAdmin } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { signOut, setDataInvestor, dataInvestor, currentUser } =
    useContext(AuthContext);

  const matches = useMediaQuery("(max-width:600px)");
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const validateCurrentInvestor = async () => {
    let response = await Http.instance.validateInversor();
    if (response.status === 200) {
      setDataInvestor(response.data[0]);
    } else {
      setDataInvestor(false);
    }
  };

  let sanitizateDataInvestor = dataInvestor || {};

  useEffect(() => {
    console.log(user.role);
    if (user.role === "4") {
      validateCurrentInvestor();
    } else if (user.role === "5") {
      //fundingRequests();
    } else {
      console.log("Rol no identificado");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          color={localConnect ? "info" : "primary"}
        >
          <Toolbar>
            {/* menu mobile */}
            {matches ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  anchor={"MenuMobile"}
                  onClick={() => setShowMenuMobile(!showMenuMobile)}
                  edge="start"
                  sx={{
                    marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>

                <MuiDrawer
                  anchor={"left"}
                  open={showMenuMobile}
                  onClose={() => setShowMenuMobile(!showMenuMobile)}
                >
                  <Box>
                    <DrawerHeader />
                    {/* ListNavigationInversor */}

                    {currentUser.role === "4" && (
                      <ListNavigationInversor
                        navigate={navigate}
                        signOut={signOut}
                        dataInvestor={sanitizateDataInvestor}
                        open={setShowMenuMobile}
                      />
                    )}

                    {currentUser.role === "5" && (
                      <ListNavigationAdmin
                        navigate={navigate}
                        signOut={signOut}
                        dataInvestor={sanitizateDataInvestor}
                        open={setShowMenuMobile}
                      />
                    )}

                    {currentUser.role === "1" && (
                      <ListNavigationAdmin
                        navigate={navigate}
                        signOut={signOut}
                        dataInvestor={sanitizateDataInvestor}
                        open={setShowMenuMobile}
                      />
                    )}
                    <ListItem button onClick={signOut}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Salir"} />
                    </ListItem>
                  </Box>
                </MuiDrawer>
              </>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap component="div">
              {name || "LobosMillonarios"} {localConnect && "DATA LOCAL"}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <Drawer variant= open={open}> */}
        <Drawer variant={matches ? "temporary" : "permanent"} open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <Divider />
          {/* List Navigation */}

          {currentUser.role === "4" && (
            <ListNavigationInversor
              navigate={navigate}
              signOut={signOut}
              dataInvestor={sanitizateDataInvestor}
              open={setShowMenuMobile}
            />
          )}

          {currentUser.role === "5" && (
            <ListNavigationAdmin
              navigate={navigate}
              signOut={signOut}
              dataInvestor={sanitizateDataInvestor}
              allFundingSuperAdmin={allFundingSuperAdmin}
              open={setShowMenuMobile}
            />
          )}
          {currentUser.role === "1" && (
            <ListNavigationAdmin
              navigate={navigate}
              signOut={signOut}
              dataInvestor={sanitizateDataInvestor}
              allFundingSuperAdmin={allFundingSuperAdmin}
              open={setShowMenuMobile}
            />
          )}
          <ListItem button onClick={signOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Salir"} />
          </ListItem>
        </Drawer>
        <Box
          component="main"
          padding={{ xs: 1, md: 2, lg: 4 }}
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            paddingTop: 5,
            background: "#e3e3e3",
          }}
          style={{}}
        >
          <DrawerHeader />

          {props.children}
        </Box>
      </Box>
    </>
  );
}
