import React, { useEffect, useState } from "react";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { separator } from "../../../lib/formatNumber";
// iconos
import FactCheckIcon from "@mui/icons-material/FactCheck";

export default function DataGridWithdrwals({ list, email }) {
	const [data, setData] = useState([]);
	useEffect(() => {
		const listData = list[email]?.data;
		list[email]?.data?.length > 0 && setData(listData);
	}, [list]);

	const columns = [
		{ field: "id", headerName: "ID", width: 70, sortable: false },

		{
			field: "created_on",
			headerName: "Fecha creación",
			width: 200,
			sortable: true,
			renderCell: (params) => {
				let date = params.row.created_on;
				return <>{moment(date).format("YYYY/MM/DD HH:mm")}</>;
			},
		},
		{
			field: "nombre",
			headerName: "Nombre",
			width: 200,
			sortable: true,
			renderCell: (params) => {
				let name = params.row.nombre;
				return <>{name}</>;
			},
		},
		{
			field: "metodo_de_retiro",
			headerName: "Metodo  Retiro ",
			width: 200,
			sortable: true,
			renderCell: (params) => {
				let metodo = params.row.metodo_de_retiro;
				return (
					<div key={params.row.id}>
						{metodo === "1" ? " Transferencia " : " Cripto"}
					</div>
				);
			},
		},

		{
			field: "moneda",
			headerName: "Moneda",
			width: 180,
			sortable: true,
			renderCell: (params) => {
				return (
					<>
						{params.row.moneda === "1" && "MXN -P Mexicano "}
						{params.row.moneda === "2" && "USD Dolar"}
						{params.row.moneda === "3" && "Cripto - USDT"}
					</>
				);
			},
		},
		{
			field: "es_parcial",
			headerName: "Tipo de retiro",
			width: 160,
			sortable: true,
			renderCell: (params) => {
				let tipo = params.row.es_parcial;
				return (
					<>
						{tipo ? (
							<Typography
								textAlign={"center"}
								color="primary"
								variant="p"
								m={1}
							>
								Retiro parcial
							</Typography>
						) : (
							<Typography
								textAlign={"center"}
								color="secondary"
								variant="p"
								m={1}
							>
								Retiro Total
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "monto",
			headerName: "Monto",
			width: 200,
			sortable: true,
			renderCell: (params) => {
				return <>{separator(params.row.monto)}</>;
			},
		},

		{
			field: "Destino",
			headerName: "Destino",
			width: 200,
			sortable: true,
			renderCell: (params) => {
				return (
					<>
						{params.row.metodo_de_retiro === "1" && params.row.banco}
						{params.row.metodo_de_retiro === "2" && "Wallet"}
					</>
				);
			},
		},
	];

	return (
		<>
			<Box
				component={Paper}
				sx={{
					height: "350px",
					width: "100%",
					marginTop: "10px",
					marginBottom: "10px",
				}}
			>
				<DataGrid columns={columns} rows={data} />
			</Box>
		</>
	);
}
